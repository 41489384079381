@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~react-dragula/dist/dragula.css";
@import "react-datepicker/dist/react-datepicker.css";
@import "~@frostbyte-technologies/frostbyte-tailwind/dist/index.scss";
@import "~react-vis/dist/styles/legends";
@import "~react-vis/dist/style";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //min-width: 640px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.global-search {
  box-shadow: none !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.calendar-with-time {
  min-width: 328px;
}

.fc {
  @apply font-sans;
  @apply bg-white;
}

@media print {
  .fc .fc-scrollgrid {
    table-layout: auto #{!important};
  }

  .fc .fc-scrollgrid table {
    table-layout: auto #{!important};
  }
}

.fc .fc-button {
  font-size: large;
  background-image: none;
  @apply inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 #{!important};
}

.fc-event {
  border-width: 3px !important;
}

.fc-resource-timeline-divider .fc-cell-shaded {
  @apply px-0 border-0 #{!important}
}

.fc-cell-shaded {
  @apply bg-gray-50 px-0 py-0 text-left text-sm font-normal text-gray-900 align-middle #{!important};
  @apply border-t border-gray-200 #{!important};
}

.react-datepicker-popper {
  z-index: 20 !important;
}

.fc-timeline-slot-cushion {
  @apply flex-1 #{!important};
}

.fc-timeline-slot {
  @apply w-24 #{!important}
}

.fc-timeline-slot-lane {
  @apply w-24 #{!important}
}

.fc .fc-resource-timeline-divider {
  width: 0px #{!important};
}

fc-datagrid-expander fc-datagrid-expander-placeholder {
  display: none #{!important};
}

.fc-icon.fc-icon-minus-square {
  display: inline-block #{!important};
}

.fc-icon.fc-icon-plus-square {
  display: inline-block #{!important};
}

.fc-icon {
  display: none #{!important};
}

.fc .fc-datagrid-expander-placeholder {
  display: none #{!important};
}

.fc-list-day-text {
  margin-left: 8px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.fc-list-day-side-text {
  margin-right: 8px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.triangle {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  box-sizing: border-box;
  border: 1em solid black;
  border-color: transparent transparent rgb(75 85 99) rgb(75 85 99);

  transform-origin: 0 0;
  transform: rotate(-45deg);

  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.align-triangle-left {
  margin-left: 45px;
}

.align-triangle-right {
  margin-left: 52px;
}

.week:hover {
  @apply bg-gray-200 #{!important};
}

.dayOutOfMonth {
  @apply bg-gray-50
}

.dayInMonth {
  @apply bg-white
}

.week:hover .dayOutOfMonth {
  @apply bg-gray-200
}

.week:hover .dayInMonth {
  @apply bg-gray-200
}


//.fc-resource {
//  border: 0px #{!important};
//}
